import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import BlogMainSection from '../components/Blog-main-section';
import { useBlogs } from '../hooks/blogs';
import Pagination from '../components/Pagination';
import { useFiles } from '../hooks/files';

const BlogPage = (props) => {
  const blogs = useBlogs();
  const { blogSlider } = useFiles();

  return (
    <Layout>
      <HeadPanel
        simple
        img={blogSlider}
        title={'مقالات و دانستنیها'}
      ></HeadPanel>
      <BlogMainSection posts={blogs}></BlogMainSection>
      <Pagination pageContext={props.pageContext} pathPrefix="/blog/" />
    </Layout>
  );
};

export default BlogPage;
