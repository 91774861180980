import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { colors, Grid, Cell } from '../theme';
import ButtonLink from './Buttons';
import { endpoints } from '../theme';
import { Link } from 'gatsby';
import GatsbyBackgroundImage from './Gatsby-background-image';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

const ArticleContainer = styled.div`
  padding: 3rem 2rem;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #323232;
  border-bottom: none;
  margin-bottom: 4rem;
`;

const PostHeading = styled.div`
  margin-bottom: 1.5rem;
  padding: 2rem;
`;

const PostTitle = styled(Link)`
  font-weight: 900;
  font-size: 2rem;
  word-wrap: break-word;
  color: #45aaff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    color: #304970;
  }
`;

const BlogFigure = styled.figure`
  background-image: url(${(props) => props.bgUrl});
  padding-bottom: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1rem;
`;

const PostContent = styled.p`
  margin-bottom: 1.5rem;
  padding: 2rem 0;
  text-align: justify;
`;

const Post = (props) => {
  const prefix = props.diseases ? 'disease' : 'blog';
  return (
    <ArticleContainer>
      <PostHeading>
        <PostTitle to={`/${prefix}/${props.id}`}>{props.title}</PostTitle>
      </PostHeading>
      {props.date && (
        <ButtonLink
          link={`/${prefix}/${props.id}`}
          title={new Date(props.date).toLocaleDateString('fa-IR')}
          bgColor={colors.blue.blogBtn}
          bgColorIcon={colors.blue.blogBtn}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="calendar-alt"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="white"
              d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
            ></path>
          </svg>
        </ButtonLink>
      )}

      {props.img ? (
        <BlogFigure>
          <GatsbyBackgroundImage img={props.img} noShadow />
        </BlogFigure>
      ) : (
        <BlogFigure bgUrl={props.bgUrl}></BlogFigure>
      )}

      <PostContent dangerouslySetInnerHTML={{ __html: props.excerpt }} />
      <ButtonLink
        link={`/${prefix}/${props.id}`}
        title={'ادامه مطلب'}
        bgColor={colors.blue.captionBlue}
      ></ButtonLink>
    </ArticleContainer>
  );
};

const BlogMainSection = (props) => {
  const data = props.posts ?? props.diseases;
  return (
    <SectionLayout>
      <Container>
        <Grid rowGap={'1.5rem'} columnGap={'3.5rem'} rows={1} columns={2}>
          {data.map((post) => {
            return (
              <Cell key={post.wordpress_id}>
                <Post
                  diseases={!!props.diseases}
                  id={post.wordpress_id}
                  title={post.title}
                  excerpt={post.excerpt}
                  date={props.posts ? post.date : null}
                  bgUrl={post.featured_media.localFile?.publicURL}
                  img={post.featured_media.localFile.childImageSharp.fluid}
                />
              </Cell>
            );
          })}
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default BlogMainSection;
